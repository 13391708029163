import {
  IdsList,
  IdsLi,
  IdsLiText,
  IdsLiSubText,
  IdsLiDescription
} from '@ids/react'
import { Shimmer, If } from 'components'
import { formatters } from 'utils'

import useDefaultList from './hooks'

const DefaultList = ({ isEditing, isEditingLoading, proposal }) => {
  const { handleEditButton } = useDefaultList({ proposal })

  return (
    <IdsList>
      <IdsLi
        disabled={isEditing || isEditingLoading}
        tagName="button"
        type="button"
        onClick={handleEditButton}
      >
        <IdsLiText>
          Parcelas
          <Shimmer isVisible={isEditingLoading}>
            <IdsLiSubText>
              {proposal?.installmentsNumber}x de R${' '}
              {formatters.number.toFloat(proposal?.installmentsValue)}
            </IdsLiSubText>
          </Shimmer>
        </IdsLiText>

        <IdsLiDescription>Editar</IdsLiDescription>
      </IdsLi>
      <IdsLi>
        <IdsLiText>Dinheiro na conta até</IdsLiText>
        <IdsLiDescription>
          <IdsLiText>
            {proposal?.heroOfferHighlights?.[0]?.infosDescription}
          </IdsLiText>
        </IdsLiDescription>
      </IdsLi>
      <If
        condition={proposal?.paymentValue}
        renderIf={
          <IdsLi lineOff>
            <IdsLiText>Total a pagar</IdsLiText>
            <IdsLiSubText>Juros</IdsLiSubText>
            <IdsLiDescription>
              <Shimmer isVisible={isEditingLoading}>
                <IdsLiText>
                  R$ {formatters.number.toFloat(proposal?.paymentValue)}
                </IdsLiText>{' '}
                <br />
                <IdsLiSubText>
                  {formatters.number.toPercent(
                    proposal?.interestRateMonthly,
                    2
                  )}{' '}
                  ao mês
                </IdsLiSubText>
              </Shimmer>
            </IdsLiDescription>
          </IdsLi>
        }
      />
    </IdsList>
  )
}

export default DefaultList
