import { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'

import {
  useProposal,
  useMapper,
  useLead
} from '@itau-loans-www/shopping/src/hooks'
import { finishProposalCallback } from '@itau-loans-www/shopping/src/hooks/useOnContinue'
import { http } from '@itau-loans-www/shopping/src/services'
import { goTo } from 'utils'

import { DDPageError } from 'utils/datadog/events'

export const useSimulation = () => {
  const [isEditing, setIsEditing] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isToRerender, setIsToRerender] = useState(false)
  const [isEditingLoading, setIsEditingLoading] = useState(false)
  const { product, setProduct } = useProposal()
  const { mapSimulationsWhenEdit } = useMapper()
  const { changeLeadData } = useLead()

  const { maxLoanValue, offerUuid, requestedLoanValue, productCode } =
    product?.proposal ?? {}
  const value = requestedLoanValue > 0 ? requestedLoanValue : maxLoanValue
  const [requestedDefaultValue, setRequestedDefaultValue] = useState(value)

  const {
    control,
    getValues,
    setValue,
    setFocus,
    formState: { isValid }
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      value: requestedDefaultValue
    }
  })

  useEffect(() => {
    changeLeadData({
      previousStep: 'offers'
    })
  }, [])

  const handleEdit = () => {
    setFocus('value')
    setIsEditing(true)
  }

  const updateValues = (value) =>
    new Promise((resolve) => {
      setValue('value', value)

      setRequestedDefaultValue(value)
      setIsToRerender(true)
      resolve()
    })

  const handleEditSubmit = async () => {
    try {
      setIsEditingLoading(true)
      const newData = await http.proposal.editProposalData(
        offerUuid,
        getValues()
      )
      setIsEditingLoading(false)

      await updateValues(newData?.requested_loan_value)
      setIsToRerender(false)

      const formattedData = mapSimulationsWhenEdit(newData)

      setProduct(formattedData)

      setIsEditing(false)
    } catch (err) {
      setIsEditing(false)
      setIsEditingLoading(false)

      const errorPayload = {
        error: err?.message,
        requestURL: err?.request?.responseURL
      }

      DDPageError({
        name: 'useSimulation | editProposal',
        payload: errorPayload
      })
    }
  }

  const handleSubmit = async () => {
    if (
      ['FGTS', 'fgts_app', 'consig_refin_inss_ncor', 'consig_ncor'].includes(
        productCode
      )
    ) {
      goTo('loan-review')

      return
    }

    try {
      await finishProposalCallback({
        proposal: product?.proposal,
        setButtonLoading: setIsLoading,
        setProduct
      })
    } catch (err) {
      const errorPayload = {
        error: err?.message,
        requestURL: err?.request?.responseURL
      }

      DDPageError({
        name: 'useSimulation | finishProposal',
        payload: errorPayload
      })
    }
  }

  return {
    isEditing,
    handleEdit,
    isEditingLoading,
    handleEditSubmit,
    requestedDefaultValue,
    isToRerender,
    control,
    isValid,
    handleSubmit,
    isLoading
  }
}
