import { IdsIcon, IdsMainButton, IdsLoading } from '@ids/react'
import { useProposal } from '@itau-loans-www/shopping/src/hooks'
import { Navigation, Input, If } from 'components'
import ProposalLayout from 'layouts/Proposal'
import { formatters } from 'utils'

import DefaultList from './components/DefaultList'
import FGTSList from './components/FGTSList'
import './simulation.scss'
import { useSimulation } from './useSimulation'

const Simulation = ({ pageContext }) => {
  const { product } = useProposal()

  const proposal = product?.proposal

  const {
    isEditing,
    handleEdit,
    isEditingLoading,
    handleEditSubmit,
    requestedDefaultValue,
    isToRerender,
    control,
    isValid,
    handleSubmit,
    isLoading
  } = useSimulation()

  return (
    <ProposalLayout pageContext={pageContext}>
      <div className="simulation">
        <h1 className="simulation__title">Simule do seu jeito</h1>
        <h2 className="simulation__subtitle">
          Escolha quanto você precisa e como quer pagar
        </h2>
        <form className="form" onSubmit={(e) => e.preventDefault()}>
          <div className="simulation__input">
            <Input.Currency
              label="Quanto você precisa"
              id="customize"
              type="currency"
              name="value"
              data-cy="cy-input-edit-value"
              shouldResetOn={isToRerender}
              defaultValue={requestedDefaultValue}
              rules={{
                min: proposal?.minLoanValue,
                max: proposal?.maxLoanValue
              }}
              control={control}
              disabled={!isEditing || isEditingLoading}
            />
            <button
              type="button"
              className="simulation__edit-btn"
              disabled={isEditing}
              onClick={handleEdit}
            >
              <IdsIcon variant="primary" size="M">
                editar_outline
              </IdsIcon>
            </button>
            <p className="simulation__input-disclaimer">
              Entre {formatters.number.toCurrency(proposal?.minLoanValue, 0)} e{' '}
              {formatters.number.toCurrency(proposal?.maxLoanValue, 0)}
            </p>
          </div>
          <div className="simulation__details">
            <If
              condition={['FGTS', 'fgts_app'].includes(proposal?.productCode)}
              renderIf={
                <FGTSList
                  proposal={proposal}
                  isEditingLoading={isEditingLoading}
                />
              }
              renderElse={
                <DefaultList
                  isEditing={isEditing}
                  isEditingLoading={isEditingLoading}
                  proposal={proposal}
                />
              }
            />
          </div>
          <If
            condition={isEditing}
            renderIf={
              <IdsMainButton
                onClick={handleEditSubmit}
                disabled={isEditingLoading || !isValid}
                full={true}
                className="simulation__edit-submit-btn"
                loading={isEditingLoading}
              >
                <If
                  condition={isEditingLoading}
                  renderElse={<>Simular</>}
                  renderIf={
                    <IdsLoading
                      className="wrapper-sendeer__submit-loading"
                      aria-live="polite"
                      aria-label="aguarde, estamos enviando seus dados..."
                      size="XS"
                      show={true}
                    />
                  }
                />
              </IdsMainButton>
            }
            renderElse={
              <Navigation
                nextStep
                nextButtonText="Ir para revisão"
                isNextStepButtonLoading={isLoading}
                nextButtonId="simulation-btn"
                onNextStepClick={handleSubmit}
              />
            }
          />
        </form>
      </div>
    </ProposalLayout>
  )
}

export default Simulation
