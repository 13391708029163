import { goTo } from 'utils'

const useDefaultList = ({ proposal }) => {
  const handleEditButton = () => {
    if (
      ['consig_ncor', 'consig_refin_inss_ncor'].includes(proposal?.productCode)
    ) {
      goTo('edit-installments')

      return
    }

    goTo('edit-installment-value')
  }

  return { handleEditButton }
}

export default useDefaultList
